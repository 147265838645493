import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import Axios from "axios";

import Alert from "../Alert";

import { apiUrl } from "../../Vars";

import SetPassword from "../SetPassword";
import UserBillingData from "./Order/UserBillingData";
import UserShippingData from "./Order/UserShippingData";
import AdminUserOrdersTableAlt from "./User/AdminUserOrdersTableAlt";
import AdminUserUpdateType from "./User/AdminUserUpdateType";

export default function AdminUser() {
  const userId = useParams().id;

  console.log(userId);

  const [user, setUser] = useState();
  const [b, setB] = useState();
  const [s, setS] = useState();
  const [isPennding, setIsPennding] = useState(true);
  const [err, setErr] = useState();

  const [alertContent, setAlertContent] = useState();
  const [alertIsOn, setAlertIsOn] = useState();

  useEffect(() => {
    Axios.get(`${apiUrl}/api/admin/users/${userId}`, {
      headers: {
        "x-access-token": `${sessionStorage.getItem("token")}`,
      },
      withCredentials: true,
    })
      .then((res) => {
        setB(res.data.userData.billingAddress);
        setS(res.data.userData.shippingAddress);
        setUser(res.data.userData.user);
        setIsPennding(false);
        setErr(null);
        console.log(res.data.userData);
      })
      .catch((err) => {
        console.log(err);
        setIsPennding(false);
        setErr(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // user form section ---------------------------------

  // alert section ---------------------------------------------------

  const showAlert = (alert, state) => {
    setAlertContent(alert);
    setAlertIsOn(state);
  };

  // render section ------------------------------------------------------------

  return (
    <>
      {err ? <div>{err}</div> : <></>}
      {isPennding ? (
        <></>
      ) : (
        <div>
          <div className="userDetails">
            <Alert
              alert={alertContent}
              alertIsOn={alertIsOn}
              showAlert={showAlert}
            />
            <div>
              <div>
                Imię i nazwisko:{" "}
                {b ? (
                  <span className="font-bold text-red-700">
                    {b.name} {b.surname}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div>
                Email użytkownika <span className="font-bold text-cyan-700">{user.email}</span>
              </div>
            </div>
            <div>
              <div>
                Id użytkownika <span className="font-bold ">#{user.id}</span>
              </div>
              <div>
                Data rejestracji: <span className="font-bold">{new Date(user.timestamp).toLocaleDateString("ISO", { day: "numeric", month: "short", year: "numeric" })}</span>
              </div>
              <div>
                <AdminUserUpdateType
                  type={user.type}
                  userId={userId}
                />
              </div>
            </div>
            <div className="set-password">
              <SetPassword
                userId={userId}
                email={user.email}
                setAlertContent={setAlertContent}
                setAlertIsOn={setAlertIsOn}
              />
            </div>
          </div>
          <div className="flex flex-row gap-5 sm:flex-col lg:flex-row mt-12">
            <div className="w-72">
              <UserBillingData
                billingData={b}
                setBillingData={setB}
                userId={userId}
                isCompany={true}
              />
            </div>
            <div className="w-72">
              <UserShippingData
                shippingData={s}
                setShippingData={setS}
                userId={userId}
              />
            </div>
          </div>
        </div>
      )}
      <br />
      <h3>Historia zamówień</h3>
      <AdminUserOrdersTableAlt userId={userId} />
      <div className="marginTop"></div>
      <NavLink to="/users">
        <button
          className="btn-sm marginTop"
          form="productForm"
        >
          Wróć do listy użytkowników
        </button>
      </NavLink>
    </>
  );
}
