import Axios from "axios";

import { apiUrl } from "../Vars";

const createParcelRdz = async (orderId) => {
  const response = await Axios.post(
    `${apiUrl}/api/admin/create-parcel-rdz/${orderId}`,
    {
      orderId,
    },
    {
      headers: {
        "x-access-token": `${sessionStorage.getItem("token")}`,
      },
      withCredentials: true,
    }
  );
  console.log(response);
  return response;
};

export { createParcelRdz };
