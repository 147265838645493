import { NavLink } from "react-router-dom";

export default function AdminDiscountTable({ discounts, isPennding, err }) {
  return (
    <div className="adminOrders">
      <h3>Produkty w sklepie</h3>
      <div className="adminOrders__row hover-none border-none">
        <div className="adminOrders__col prodName heading discount">Nazwa</div>
        <div className="adminOrders__col prodNetto heading discount">Typ</div>
        <div className="adminOrders__col prodNetto heading discount">Wartość</div>
        <div className="adminOrders__col prodNetto heading discount">Ważność</div>
      </div>
      {isPennding ? <div>Wczytywanie danych</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {discounts ? (
        <div className="adminOrders__table">
          {discounts.map((discount) => {
            return (
              <NavLink
                to={`${discount.name}`}
                key={discount.name}
              >
                <div className="adminOrders__row">
                  <div className="adminOrders__col discount">{discount.name}</div>
                  <div className="adminOrders__col discount">{discount.type}</div>
                  <div className="adminOrders__col discount">{discount.value}</div>
                  <div className="adminOrders__col discount">{discount.expire}</div>
                </div>
              </NavLink>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <NavLink to="/discounts/add">
        <button
          className="btn-sm marginTop"
          form="productForm"
        >
          Dodaj kod
        </button>
      </NavLink>
    </div>
  );
}
