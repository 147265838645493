import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router";
import Axios from "axios";

import { apiUrl } from "../../Vars";

import { AddInvoice } from "../../controllers/AddInvoice";
import { createParcelRdz } from "../../controllers/Parcel";
import Button from "../Layout/Button";
import BillingData from "./Order/BillingData";
import ShippingData from "./Order/ShippingData";
import Notes from "./Order/Notes";
import OrderDetails from "./Order/OrderDetails";

export default function AdminOrder() {
  const orderIndex = useParams().id;

  const location = useLocation();

  const [order, setOrder] = useState();
  const [isPennding, setIsPennding] = useState(true);

  const [orderDate, setOrderDate] = useState();
  const [billingData, setBillingData] = useState();
  const [shippingData, setShippingData] = useState();
  const [orderItems, setOrderItems] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [shippmentStatus, setShippmentStatus] = useState();
  const [orderInvoice, setOrderInvoice] = useState();
  const [refObject, setRefObject] = useState();
  const [parcelWeight, setParcelWeight] = useState();
  const [clientType, setClientType] = useState();
  const [shippingMethod, setShippingMethod] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [invoiceType, setInvoiceType] = useState();
  const [discountOrder, setDiscountOrder] = useState();
  const [discountOrderValue, setDiscountOrderValue] = useState();
  const [user, setUser] = useState();

  const [idle, setIdle] = useState(false);

  console.log(order);
  console.log(user);
  console.log(billingData);

  useEffect(() => {
    if (idle === true) {
      const interval = setInterval(() => {
        setIdle(false);
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [idle]);

  useEffect(() => {
    Axios.get(`${apiUrl}/api/admin/order/${orderIndex}`, {
      headers: {
        "x-access-token": `${sessionStorage.getItem("token")}`,
      },
      withCredentials: true,
    })
      .then((res) => {
        const o = res.data.order;
        const u = res.data.user;
        setUser(u);
        setOrder(o);
        setOrderDate(new Date(o.timestamp));
        setBillingData(JSON.parse(o.billingData));
        setShippingData(JSON.parse(o.shippingData));
        setOrderItems(JSON.parse(o.orderItems));
        setPaymentStatus(o.paymentStatus);
        setShippmentStatus(o.shippmentStatus);
        setOrderInvoice(JSON.parse(o.invoice));
        setIsPennding(false);
        if (o.refParam !== null) {
          Axios.get(`${apiUrl}/api/admin/order/ref/${o.refParam}`, {
            headers: {
              "x-access-token": `${sessionStorage.getItem("token")}`,
            },
            withCredentials: true,
          }).then((res2) => {
            setRefObject(res2.data[0]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // marked section ---------------------------------------------

  useEffect(() => {
    if (order && order.unread === "true") {
      markRead(order.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const markRead = (orderId) => {
    Axios.post(
      `${apiUrl}/api/admin/orders/mark/${orderId}`,
      { body: null },
      {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        console.log("Oznaczone");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // status section ---------------------------------------------

  const submitPayment = (e) => {
    e.preventDefault();
    paymentStatusChange(order.id, paymentStatus);
  };

  const submitShippment = (e) => {
    e.preventDefault();
    shippmentStatusChange(order.id, shippmentStatus);
  };

  const paymentStatusChange = (orderId, status) => {
    Axios.post(
      `${apiUrl}/api/admin/orders/update-payment/${orderId}`,
      {
        status,
      },
      {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        setIdle(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shippmentStatusChange = (orderId, status) => {
    Axios.post(
      `${apiUrl}/api/admin/orders/update-shippment/${orderId}`,
      {
        status,
      },
      {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        setIdle(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // parcel section ------------------------------------

  useEffect(() => {
    let weight = 0;
    if (orderItems) {
      orderItems.forEach((orderItem) => {
        weight = weight + parseFloat(orderItem.weight) * orderItem.quantity;
      });
    }
    setParcelWeight(weight.toFixed(2));
  }, [orderItems]);

  console.log(parcelWeight);

  const createParcel = () => {
    let serviceId = 0;
    if (order.shippingMethod === "box") {
      serviceId = 28034383;
    } else if (order.shippingMethod === "delivery") {
      serviceId = 28034384;
    }

    let parcelDesc = `${order.type}:`;
    let desc = "";
    for (let i = 0; i < orderItems.length; i++) {
      desc = desc + `${orderItems[i].short_name}(${orderItems[i].quantity})`;
    }
    parcelDesc = parcelDesc + desc;

    const orderDateString = orderDate.toLocaleDateString("ISO", { day: "numeric", month: "short", year: "numeric" }) + ", godz. " + orderDate.getHours() + ":" + (orderDate.getMinutes() <= 10 ? "0" + orderDate.getMinutes() : orderDate.getMinutes());

    const cod = {
      amount: order.orderValue,
      currency: "PLN",
      express: false,
      iban: "PL05804600022001010702640001",
      name: "SAINT LAZARUS SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ",
      swift: "POLUPLPR",
    };

    const additional_services_object = {
      cod,
      insurance: true,
    };

    const additional_services_object_insurance = {
      insurance: false,
    };
    const additional_services = order.paymentMethod === "on_delivery" ? additional_services_object : additional_services_object_insurance;

    const parcel = {
      reciver: {
        name: `${shippingData.name} ${shippingData.surname}`,
        company: shippingData.companyName,
        street: `${shippingData.street} ${shippingData.addressNumber1} / ${shippingData.addressNumber2 === null ? "" : shippingData.addressNumber2}`,
        postcode: shippingData.zipCode,
        city: shippingData.city,
        country_code: shippingData.country,
        email: billingData.email,
        phone: shippingData.phone.toString(),
        point: shippingData.pointName === null ? null : shippingData.pointName,
      },
      service_id: serviceId,
      type: "package",
      parcels: [
        {
          width: 30,
          depth: 20,
          height: 15,
          weight: parcelWeight || 2,
          value: order.paymentMethod === "on_delivery" ? order.orderValue : 0,
          description: `#${order.id}:${parcelDesc}`,
        },
      ],
      orderId: order.id,
      clientName: billingData.name,
      clientSurname: billingData.surname,
      clientEmail: billingData.email,
      orderDateString: orderDateString,
      additional_services: { ...additional_services },
    };

    Axios.post(
      `${apiUrl}/api/admin/create-parcel`,
      {
        parcel,
      },
      {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        console.log(res);
        alert(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // invoice section ------------------------------------------------------

  // if (order) {
  //   console.log(JSON.parse(order.invoice))
  // }

  // const data = {
  //   country: 'pl'
  // }

  // const isCompany = true

  // const paymentMethod = 'payu'

  // const invoice = {
  //   type: 'normal',
  //   type_of_sale: '',
  //   price_type: data.country === 'pl' ? 'brutto' : 'netto',
  //   currency: 'PLN',
  //   paymentstate: '',
  //   paymentdate: '',
  //   alreadypaid_initial: '',
  //   paymentId: '',
  //   buyer_type: isCompany ? 'company' : 'personal',
  //   paymentMethod: paymentMethod,
  //   auto_send: '',
  //   semitemplatenumber: "FV [numer]/[miesiąc]/2023",
  // }

  // data section -------------------------------------------------------------

  // const [clientType, setClientType] = useState()
  // const [shippingMethod, setShippingMethod] = useState()
  // const [paymentMethod, setPaymentMethod] = useState()
  // const [invoiceType, setInvoiceType] = useState()
  // const [referral_user, setReferralUser] = useState()
  // const [discountOrder, setDiscountOrder] = useState()
  // const [discountOrderValue, setDiscountOrderValue] = useState()

  useEffect(() => {
    if (order) {
      switch (true) {
        case order.shippingMethod === "box":
          setShippingMethod("Paczkomat");
          break;
        case order.shippingMethod === "delivery":
          setShippingMethod("Kurier");
          break;
        default:
          break;
      }

      switch (true) {
        case order.paymentMethod === "payU":
          setPaymentMethod("PayU");
          break;
        case order.paymentMethod === "transfer":
          setPaymentMethod("Przelew");
          break;
        case order.paymentMethod === "on_delivery":
          setPaymentMethod("Pobranie");
          break;
        default:
          break;
      }

      switch (true) {
        case orderInvoice.discountName === "none":
          setDiscountOrder("none");
          break;
        case orderInvoice.discountName !== "none":
          setDiscountOrder(orderInvoice.discountName);
          setDiscountOrderValue(orderInvoice.discountValue);
          break;
        default:
          break;
      }

      switch (true) {
        case order.type === "DETAL" && orderInvoice.buyer_type === "company":
          setInvoiceType("Faktura");
          break;
        case order.type === "DETAL" && orderInvoice.buyer_type === "personal":
          setInvoiceType("Paragon");
          break;
        default:
          setInvoiceType("Faktura");
          break;
      }

      switch (true) {
        case user && user.type === "partner30":
          setClientType("Partner");
          break;
        case user && user.type === "client":
          setClientType("Gabinet");
          break;
        case user && user.type === "rdz2":
          setClientType("Gabinet rekomendowany");
          break;
        case user && user.type === "rdz":
          setClientType("Gabinet autoryzowany");
          break;
        case user && user.type === "rdz3":
          setClientType("Gabinet RDZ");
          break;
        case user && user.type === "admin":
          setClientType("Admin");
          break;
        case user && user.type === "manager":
          setClientType("Manager");
          break;
        default:
          setClientType("Gość");
          break;
      }
    }
  }, [order, orderInvoice, user]);

  // render section ------------------------------------------------------------

  return (
    <>
      {isPennding ? (
        <></>
      ) : (
        <div className="adminOrdersSingle">
          {!order && !order.id ? (
            <div>Ladowanie...</div>
          ) : (
            <div className="adminOrdersSingle__data">
              <h3>
                Zamówienie typ: {order.type} numer: #{order.id}
              </h3>
              <p className="text-yellow-500 font-bold">
                Złożone: {orderDate.toLocaleDateString("ISO", { day: "numeric", month: "short", year: "numeric" })} o {orderDate.getHours()}:{orderDate.getMinutes() <= 10 ? "0" + orderDate.getMinutes() : orderDate.getMinutes()}
              </p>
              <p>
                {" "}
                Zamawiający:{" "}
                <strong>
                  {billingData.name} {billingData.surname}
                </strong>
                , <span className="text-green-500 font-bold">{billingData.email}</span>, typ: <span className="text-orange-500 font-bold">{clientType}</span>
              </p>

              <p className="">
                Metoda płatności: <strong className="text-red-500">{paymentMethod}</strong>, metoda wysyłki: <strong className="text-cyan-500">{shippingMethod}</strong>.{" "}
              </p>
              <p>
                Dokument sprzedażowy: <strong className="text-blue-500">{invoiceType}</strong>
              </p>
              {discountOrder !== "none" && (
                <p>
                  Wykorzystano kod rabatowy: <strong className="text-yellow-500">{discountOrder}</strong> o wartości: <strong className="text-yellow-500">{discountOrderValue}%</strong>
                </p>
              )}
              {refObject ? (
                <p className="mb2">
                  Zamówienie przypisane do Ambasadora:{" "}
                  <strong className="text-pink-500">
                    {refObject.referral_user_name} {refObject.referral_user_lastname}{" "}
                  </strong>{" "}
                  - {refObject.referral_user_email}
                </p>
              ) : (
                <></>
              )}

              <div className="flex flex-col gap-5 sm:flex-row">
                <form className="statusChange">
                  <label htmlFor="status">Status płatności: </label>
                  <select
                    className={`${paymentStatus}`}
                    type="select"
                    id="status"
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  >
                    <option
                      className="PENDING"
                      value="PENDING"
                    >
                      Oczekujące
                    </option>
                    <option
                      className="COMPLETED"
                      value="COMPLETED"
                    >
                      Opłacone
                    </option>
                    <option
                      className="CANCELED"
                      value="CANCELED"
                    >
                      Anulowane
                    </option>
                  </select>
                  {idle ? (
                    <button className="btn idle">Zmieniono status</button>
                  ) : (
                    <button
                      className="btn"
                      type="submit"
                      onClick={(e) => submitPayment(e)}
                    >
                      Zmień status
                    </button>
                  )}
                </form>

                <form className="statusChange">
                  <label htmlFor="status">Status przesyłki: </label>
                  <select
                    className={`${shippmentStatus}`}
                    type="select"
                    id="status"
                    value={shippmentStatus}
                    onChange={(e) => setShippmentStatus(e.target.value)}
                  >
                    <option
                      className="PENDING"
                      value="PENDING"
                    >
                      Oczekujące
                    </option>
                    <option
                      className="PREP"
                      value="PREP"
                    >
                      Przygotowane
                    </option>
                    <option
                      className="IN_TRANSIT"
                      value="IN_TRANSIT"
                    >
                      Wysłane
                    </option>
                    <option
                      className="ON_DELIVERY"
                      value="ON_DELIVERY"
                    >
                      W doręczeniu
                    </option>
                    <option
                      className="FINISHED"
                      value="FINISHED"
                    >
                      Dostarczone
                    </option>
                    <option
                      className="CANCELED"
                      value="CANCELED"
                    >
                      Anulowane
                    </option>
                  </select>
                  {idle ? (
                    <button className="btn idle">Zmieniono status</button>
                  ) : (
                    <button
                      className="btn"
                      type="submit"
                      onClick={(e) => submitShippment(e)}
                    >
                      Zmień status
                    </button>
                  )}
                </form>
              </div>

              <br />

              <div className="clientData">
                <div className="clientData__data">
                  <BillingData
                    billingData={billingData}
                    orderId={order.id}
                    setBillingData={setBillingData}
                  />
                </div>

                <div className="clientData__data">
                  <ShippingData
                    shippingData={shippingData}
                    orderId={order.id}
                    setShippingData={setShippingData}
                  />
                </div>
              </div>

              <OrderDetails
                order={order}
                orderItems={orderItems}
                shippingMethod={shippingMethod}
                orderInvoice={orderInvoice}
              />

              <br />

              <h3 className="marginTop">Uwagi klienta</h3>
              <p>{order.orderInfo}</p>
              <br />

              <Notes
                notes={order.notes}
                orderId={order.id}
              />

              <br />

              <div className="flex flex-col gap-8 sm:flex-row">
                {invoiceType === "Faktura" && (
                  <Button
                    content={"Dodaj fakturę"}
                    action={AddInvoice}
                    option={order.id}
                    alert={"Dodano fakturę"}
                  />
                )}
                {order.shippmentStatus === "PENDING" && (
                  <>
                    {!location.pathname.includes("radzyn") && (
                      <>
                        <Button
                          content={"Dodaj przesyłkę"}
                          action={createParcel}
                          option={order.id}
                          alert={"Dodano przesyłkę"}
                        />
                      </>
                    )}
                    {location.pathname.includes("radzyn") && (
                      <>
                        <Button
                          content={"Dodaj przesyłkę"}
                          action={createParcelRdz}
                          option={order.id}
                          alert={"Dodano przesyłkę"}
                        />
                      </>
                    )}
                  </>
                )}
                {/* <button className="btn order_control">Wróć do listy zamówień</button> */}
              </div>

              <br />
            </div>
          )}
        </div>
      )}
    </>
  );
}
