import { useState, useEffect } from "react";

export default function Button({ content, action, option, alert }) {
  const [idle, setIdle] = useState(false);

  useEffect(() => {
    if (idle === true) {
      const interval = setInterval(() => {
        setIdle(false);
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [idle]);

  return (
    <>
      {idle ? (
        <button className="btn-sm idle">{alert}</button>
      ) : (
        <button
          className="btn-sm"
          onClick={() => {
            setIdle(true);
            action(option);
          }}
        >
          {content}
        </button>
      )}
    </>
  );
}
