import { useState, useEffect } from "react";
import { usePostData } from "../../../controllers/fetchData";

import { GoPencil } from "react-icons/go";

export default function ShippingData({ shippingData, orderId, setShippingData }) {
  const { response, idle, error, makePostRequest } = usePostData();

  console.log(shippingData);

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(shippingData.name);
  const [surname, setSurname] = useState(shippingData.surname);
  const [companyName, setCompanyName] = useState(shippingData.companyName);
  const [pointName, setPointName] = useState(shippingData.pointName);
  const [street, setStreet] = useState(shippingData.street);
  const [addressNumber1, setAddressNumber1] = useState(shippingData.addressNumber1);
  const [addressNumber2, setAddressNumber2] = useState(shippingData.addressNumber2);
  const [city, setCity] = useState(shippingData.city);
  const [zipCode, setZipCode] = useState(shippingData.zipCode);
  const [country, setCountry] = useState(shippingData.country);
  const [phone, setPhone] = useState(shippingData.phone);

  const countries = [{ value: "" }, { name: "Polska", value: "PL" }, { name: "Niemcy", value: "DE" }, { name: "Wielka Brytania", value: "GB" }, { name: "Francja", value: "FR" }, { name: "Irlandia", value: "IR" }];

  useEffect(() => {
    if (shippingData) {
      setName(shippingData.name);
      setSurname(shippingData.surname);
      setCompanyName(shippingData.companyName);
      setPointName(shippingData.pointName);
      setStreet(shippingData.street);
      setAddressNumber1(shippingData.addressNumber1);
      setAddressNumber2(shippingData.addressNumber2);
      setCity(shippingData.city);
      setZipCode(shippingData.zipCode);
      setCountry(shippingData.country);
      setPhone(shippingData.phone);
    }
  }, [shippingData]);

  console.log(name, surname, companyName, street, addressNumber1, addressNumber2, city, zipCode, country, phone);

  const updateShippingData = (e) => {
    e.preventDefault();
    const newData = {
      name,
      surname,
      companyName,
      pointName,
      street,
      addressNumber1,
      addressNumber2,
      city,
      zipCode,
      country,
      phone,
    };
    console.log(e);
    const data = { newData, orderId };

    makePostRequest(`admin/update-shipping-data`, data, orderId);
    setShippingData(newData);
    console.log(response);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <>
          <p className="clientData__heading">Adres wysyłki</p>
          <form action="">
            <p className="flex column">
              <label htmlFor="name">Imię</label>
              <input
                type="text"
                name="name"
                placeholder="Imie"
                className="clientData__input"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="surname">Nazwisko</label>
              <input
                type="text"
                name="surname"
                placeholder="Nazwisko"
                className="clientData__input"
                defaultValue={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="companyName">Nazwa firmy</label>
              <input
                type="text"
                name="companyName"
                placeholder="Nazwa firmy"
                className="clientData__input"
                defaultValue={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="companyName">Paczkomat</label>
              <input
                type="text"
                name="pointName"
                placeholder="Paczkomat"
                className="clientData__input"
                defaultValue={pointName}
                onChange={(e) => setPointName(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="street">Ulica / Miejscowość</label>
              <input
                type="text"
                name="street"
                placeholder="Ulica / Miejscowość"
                className="clientData__input"
                defaultValue={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="addressNumber1">Numer budynku</label>
              <input
                type="text"
                name="addressNumber1"
                placeholder="Numer budynku"
                className="clientData__input"
                defaultValue={addressNumber1}
                onChange={(e) => setAddressNumber1(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="addressNumber2">Numer lokalu</label>
              <input
                type="text"
                name="addressNumber2"
                placeholder="Numer lokalu"
                className="clientData__input"
                defaultValue={addressNumber2}
                onChange={(e) => setAddressNumber2(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="zipCode">Kod pocztowy</label>
              <input
                type="text"
                name="zipCode"
                placeholder="Kod pocztowy"
                className="clientData__input"
                defaultValue={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </p>
            <p className="flex column">
              <label htmlFor="city">Miasto / Poczta</label>
              <input
                type="text"
                name="city"
                placeholder="Miasto / Poczta"
                className="clientData__input"
                defaultValue={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </p>
            <p className="flex column">
              <div className="form__input">
                <label htmlFor="country">Kraj</label>
                <select
                  name="country"
                  id="country"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </p>
            <p className="flex column">
              <label htmlFor="phone">Telefon</label>
              <input
                type="text"
                name="phone"
                placeholder="Telefon"
                className="clientData__input"
                defaultValue={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </p>
            <button
              className="btn"
              type="submit"
              onClick={(e) => updateShippingData(e)}
            >
              Zapisz
            </button>
            <button
              className="btn mt-2"
              type="submit"
              onClick={() => setIsEditing(false)}
            >
              Anuluj
            </button>
          </form>
        </>
      ) : (
        <>
          <p className="clientData__heading">
            Adres wysyłki{" "}
            <icon
              className="icon-inline"
              type="submit"
              onClick={() => setIsEditing(true)}
            >
              <GoPencil />
            </icon>
          </p>
          <p className="clientData__h">Imię i nazwisko:</p>
          <p className="clientData__value">
            {name} {surname}
          </p>
          {!companyName ? <></> : <p className="clientData__h">Firma</p>}
          <p className="clientData__value">{companyName}</p>
          <p className="clientData__h">Paczkomat</p>
          <p className="clientData__value">{pointName}</p>
          <p className="clientData__h">Adres</p>
          <p className="clientData__value">
            {street} {addressNumber1} / {addressNumber2}
          </p>
          <p className="clientData__value">
            {zipCode} {city}
          </p>
          <p className="clientData__value">{country}</p>
          <p className="clientData__h">Telefon</p>
          <p className="clientData__value">{phone}</p>
        </>
      )}
    </>
  );
}
