import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { usePostData } from "../../../controllers/fetchData";

import { GoPencil } from "react-icons/go";
import { set } from "draft-js/lib/EditorState";

export default function AdminUserUpdateType({ type, userId }) {
  const location = useLocation();

  console.log(location.pathname);

  const { response, idle, error, makePostRequest } = usePostData();

  const [isEditing, setIsEditing] = useState(false);
  const [userType, setUserType] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setUserType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes("radzyn")) {
      setTypes([
        { type: "rdz2", name: "Rekomendowany" },
        { type: "rdz", name: "Autoryzowany" },
        { type: "rdz3", name: "Gabinet" },
      ]);
    } else {
      setTypes([
        { type: "admin", name: "Admin" },
        { type: "partner30", name: "Partner30" },
        { type: "manager", name: "Manager" },
        { type: "client", name: "Gabinet" },
      ]);
    }
  }, [location]);

  const updateType = () => {
    const data = {
      type: userType,
      userId: userId,
    };
    makePostRequest(`admin/user-update-type`, data);
    console.log(response, idle, error);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <div className="w-72">
          Typ użytkownika:{" "}
          <select
            className="form__input w-72 mt-2"
            onChange={(e) => setUserType(e.target.value)}
            value={userType}
          >
            {types.map((t) => (
              <option
                key={t.type}
                value={t.type}
              >
                {t.name}
              </option>
            ))}
          </select>
          <button
            className="btn-sm mr-3 mt-2"
            onClick={() => setIsEditing(false)}
          >
            Anuluj
          </button>
          <button
            className="btn-sm mt-2"
            onClick={updateType}
          >
            Zapisz
          </button>
        </div>
      ) : (
        <div>
          Typ użytkownika: <span className={`type_${userType}`}></span>
          <icon
            className="icon-inline text-xs ml-2"
            type="submit"
            onClick={() => setIsEditing(true)}
          >
            <GoPencil />
          </icon>
        </div>
      )}
    </>
  );
}
