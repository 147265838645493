import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

import Pagination from "../Layout/Pagination";

export default function AdminUsersTable({ users, isPennding, err, setSearchValue }) {
  const [paginateUsers, setPagineteUsers] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesCount, setPagesCount] = useState();

  useEffect(() => {
    if (users && users.length !== 0) {
      setPagesCount(Math.ceil(users.length / pageSize));
    }
  }, [users, pageSize]);

  useEffect(() => {
    if (users && users.length !== 0) {
      setPagineteUsers(users.slice((currentPage - 1) * pageSize, currentPage * pageSize));
    }
  }, [users, currentPage, pageSize]);

  console.log(users);

  return (
    <div className="adminOrders">
      <h3>Użytkownicy</h3>
      <div className="adminOrders__row adminOrders__searchbar border-none hover-none">
        <div className="fieldBox">
          <label htmlFor="search">Szukaj:</label>
          <input
            type="text"
            id="search"
            autocomplete="off"
            autofill="off"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="hide-mobile adminOrders__row border-none hover-none">
        <div className="adminOrders__col id heading">Identyfikator</div>
        <div className="adminOrders__col prodName heading">Adres email</div>
        <div className="adminOrders__col userDate heading">Data utworzenia</div>
        <div className="adminOrders__col userRole heading">Rola</div>
      </div>
      {isPennding ? <div>Wczytywanie danych</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {paginateUsers ? (
        <div className="adminOrders__table">
          {paginateUsers.map((user) => {
            const userDate = new Date(user.timestamp);
            const userDateString = userDate.toLocaleDateString("ISO", { day: "numeric", month: "short", year: "numeric" });
            return (
              <NavLink
                to={`${user.id}`}
                key={user.id}
              >
                <div className="adminOrders__row admin-users">
                  <div className="hide-desktop">Identyfikator</div>
                  <div className={`adminOrders__col id`}>{user.id}</div>
                  <div className="hide-desktop">Adres email</div>
                  <div className="adminOrders__col prodName">{user.email}</div>
                  <div className="hide-desktop">Data rejestracji</div>
                  <div className="adminOrders__col userDate">{userDateString}</div>
                  <div className="hide-desktop">Typ użytkownika</div>
                  <div className={`adminOrders__col userRole type_${user.type}`}></div>
                </div>
              </NavLink>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <Pagination
        setCurentPage={setCurrentPage}
        setPageSize={setPageSize}
        pagesCount={pagesCount}
      />
    </div>
  );
}
