export const filterAction = (orders, searchTerm) => {
  return orders.filter((order) => {
    return order.id.toString().includes(searchTerm) || JSON.parse(order.billingData).email.toLowerCase().includes(searchTerm) || JSON.parse(order.billingData).name.toLowerCase().includes(searchTerm) || JSON.parse(order.billingData).surname.toLowerCase().includes(searchTerm);
  });
};

export const filterTypeAction = (orders, type) => {
  return orders.filter((order) => {
    if (type === "all") {
      return orders;
    } else {
      return order.type === type;
    }
  });
};

export const filterPaymentAction = (orders, paymentSearch) => {
  return orders.filter((order) => {
    if (paymentSearch === "") {
      return orders;
    } else {
      return order.paymentStatus === paymentSearch;
    }
  });
};

export const filterShippmentAction = (orders, shippmentSearch) => {
  return orders.filter((order) => {
    if (shippmentSearch === "") {
      return orders;
    } else {
      return order.shippmentStatus === shippmentSearch;
    }
  });
};

export const filterDateAction = (orders, fromDate, toDate) => {
  console.log(orders, fromDate, toDate);
  return orders.filter((order) => {
    const from = new Date(fromDate).getTime();
    const to = new Date(toDate).getTime();
    const toPlus = to + 1440 * 60 * 1000;
    if (fromDate !== "") {
      if (toDate !== "") {
        return order.timestamp >= from && order.timestamp <= toPlus;
      } else {
        return order.timestamp > from;
      }
    } else if (toDate !== "") {
      return order.timestamp < toPlus;
    }
    console.log(orders);
    return orders;
  });
};

export const filterUsersAction = (users, searchTerm) => {
  if (users && users.length !== 0) {
    return users.filter((user) => {
      return user.email.includes(searchTerm);
    });
  }
};

export const filterUsersByType = (users, types) => {
  console.log(users, types);
  if (users && users.length !== 0) {
    return users.filter((user) => {
      return types.includes(user.type);
    });
  }
};
