import React from "react";

export default function OrderDetails({ order, orderItems, shippingMethod, orderInvoice }) {
  return (
    <div>
      <h3 className="marginTop">Szczegóły zamówienia</h3>

      {orderItems.map((item) => (
        <div
          className="adminOrdersItem_row"
          key={item.name}
        >
          <div className="a-name">{item.name}</div>
          <div className="a-quantity">szt. {item.quantity}</div>
          <div className="a-value pl">{parseFloat(item.brutto) * item.quantity}</div>
        </div>
      ))}

      <div className="adminOrdersItem_row">
        <div className="a-name">Dostawa</div>
        <div className="a-quantity">{shippingMethod}</div>
        <div className="a-value pl">{order.shippingCost}</div>
      </div>

      {orderInvoice.discountName && orderInvoice.discountName !== "none" ? (
        <div className="adminOrdersItem_row__discount">
          <div>
            Kod rabatowy <strong>"{orderInvoice.discountName}"</strong>
          </div>
          <div className="">
            <strong>-{orderInvoice.discountValue}%</strong>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="adminOrdersItem_row__total">
        <div>Razem</div>
        <div className="pl">{Math.round(order.orderValue * 100) / 100}</div>
      </div>
    </div>
  );
}
