import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useLanguage } from "../../controllers/Language";
import Pagination from "../Layout/Pagination";
import OrderIcons from "../Layout/OrderIcons";

export default function AdminOrdersTableAlt({ sortedOrders, isPennding, err, sortAction }) {
  const [paginateOrders, setPaginateOrders] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesCount, setPagesCount] = useState();

  useEffect(() => {
    if (sortedOrders && sortedOrders.length !== 0) {
      setPagesCount(Math.ceil(sortedOrders.length / pageSize));
    }
  }, [sortedOrders, pageSize]);

  useEffect(() => {
    if (sortedOrders && sortedOrders.length !== 0) {
      setPaginateOrders(sortedOrders.slice((currentPage - 1) * pageSize, currentPage * pageSize));
    } else {
      setPaginateOrders([]);
    }
  }, [sortedOrders, currentPage, pageSize]);

  const Language = useLanguage();

  return (
    <div className="adminOrders mt-12">
      <div className="adminOrders__row">
        <div className="adminOrders__row-inner">
          <div className="ordersTable_id">
            <div className={`font-bold`}>ID</div>
            <div className={`font-bold`}>Typ</div>
          </div>
          <div className="ordersTable_client">
            <div className="font-bold text-cyan-600">Email</div>
            <div className="font-bold">Imię i nazwisko</div>
          </div>
          <div className="ordersTable_date">
            {/* <div className="">{orderDateString}</div> */}
            <div className="">Data</div>
          </div>
        </div>

        <div className="adminOrders__row-inner">
          <div className="order_icons">
            <div className="ordersTable_id">Flagi</div>
          </div>

          <div className="ordersTable_value">
            <div className={`font-bold text-cyan-600 value `}>Wartość</div>
            <div className={`payment-method text-green-600`}>Płatność</div>
          </div>

          <div className="ordersTable_status-wrapper">
            <div className="ordersTable_status-names"></div>
            <div className="ordersTable_status">
              <div className={`adminOrders__col status`}>Statusy</div>
            </div>
          </div>
        </div>
      </div>
      {isPennding ? <div>Wczytywanie danych</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {paginateOrders ? (
        <div className="adminOrders__table">
          {paginateOrders.map((order) => {
            const orderDate = new Date(order.timestamp);
            // const orderDateString = orderDate.toLocaleDateString("ISO", { day: "numeric", month: "numeric", year: "numeric" });
            const orderTimeString = orderDate.toLocaleDateString("ISO", { hour: "numeric", minute: "numeric", second: "numeric" });
            console.log(order.id);
            console.log(JSON.parse(order.invoice));
            const isInvoiceDetal = order.type === "DETAL" && JSON.parse(order.invoice).buyer_type === "company" ? true : false;
            console.log(isInvoiceDetal);
            let paymentMethod = "";
            switch (order.paymentMethod) {
              case "payU":
                paymentMethod = "PayU";
                break;
              case "transfer":
                paymentMethod = "Przelew";
                break;
              case "on_delivery":
                paymentMethod = "Pobranie";
                break;
              default:
                paymentMethod = "Brak";
            }
            const orderFlags = {
              isRef: order.refParam === null ? false : true,
              isUnread: order.unread === "true" ? true : false,
              isDiscount: JSON.parse(order.invoice).discountName !== "none" ? true : false,
              isInvoice: isInvoiceDetal ? true : false,
              orderType: order.type,
              id: order.userId,
            };
            return (
              <NavLink
                to={`${order.id}`}
                key={order.id}
              >
                <div className="adminOrders__row">
                  <div className="adminOrders__row-inner">
                    <div className="ordersTable_id">
                      <div className={`font-bold ${order.unread === "true" ? "text-red-600" : ""}`}>{order.id}</div>
                      <div className={`font-bold`}>{order.type}</div>
                    </div>

                    <div className="ordersTable_client">
                      <div className="font-bold text-cyan-600"> {JSON.parse(order.billingData).email}</div>
                      <div className="font-bold">
                        {JSON.parse(order.billingData).name.slice(0, 1)}. {JSON.parse(order.billingData).surname}
                      </div>
                    </div>

                    <div className="ordersTable_date">
                      {/* <div className="">{orderDateString}</div> */}
                      <div className="">{orderTimeString}</div>
                    </div>
                  </div>

                  <div className="adminOrders__row-inner">
                    <OrderIcons orderFlags={orderFlags} />

                    <div className="ordersTable_value">
                      <div className={`font-bold text-cyan-600 value ${Language}`}>{Math.round(order.orderValue * 100) / 100}</div>
                      <div className={`payment-method text-green-600`}>{paymentMethod}</div>
                    </div>

                    <div className="ordersTable_status-wrapper">
                      <div className="ordersTable_status-names">
                        <div>Płatność:</div>
                        <div>Wysyłka:</div>
                      </div>

                      <div className="ordersTable_status">
                        <div className={`adminOrders__col status ${order.paymentStatus}`}></div>
                        <div className={`adminOrders__col status ${order.shippmentStatus}`}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <Pagination
        setCurentPage={setCurrentPage}
        setPageSize={setPageSize}
        pagesCount={pagesCount}
      />
    </div>
  );
}
