import { useState, useEffect } from "react";

import { usePostData } from "../../../controllers/fetchData";

import Button from "../../Layout/Button";

export default function Notes({ notes, orderId }) {
  const { response, idle, error, makePostRequest } = usePostData();

  const [notesData, setNotesData] = useState([]);
  const [note, setNote] = useState("");
  const [addingNote, setAddingNote] = useState(false);

  useEffect(() => {
    setNotesData(JSON.parse(notes));
  }, [notes]);

  const AddNote = (id) => {
    const timestamp = Date.now();
    const date = new Date(timestamp).toLocaleDateString("pl-PL", { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" });

    const newNotesData = [...notesData, { note_date: date, note_text: note }];

    setNotesData(newNotesData);

    const data = {
      newNote: note,
      orderId: id,
    };

    makePostRequest(`admin/add-note`, data);

    console.log(response);

    if (error) {
      console.log(error);
    }

    setNote("");
    setAddingNote(false);
  };

  return (
    <>
      <h3>Historia zamówienia</h3>
      <div>
        {notesData &&
          notesData.map((i) => (
            <div
              className="text-sm mb-2 sm:text-base text-wrap truncate"
              key={i.note_text}
            >
              <span className="note__date">{i.note_date}</span> <span className="note__text">{i.note_text}</span>
            </div>
          ))}

        {addingNote ? (
          <div className="flex flex-col">
            <input
              type="text"
              name="note"
              placeholder="Notatka"
              className="clientData__input mb-2"
              defaultValue={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <Button
              content={"Zapisz"}
              action={AddNote}
              option={orderId}
              alert={"Dodano notatkę"}
            />
          </div>
        ) : (
          <Button
            content={"Dodaj notatke"}
            action={setAddingNote}
            option={true}
            alert={"Dodano notatke"}
          />
        )}
        <br />
        <br />
      </div>
    </>
  );
}
