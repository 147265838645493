import { NavLink } from "react-router-dom";

import { useAuth } from "../../controllers/useAuth";

export default function AdminMenu({ toggleModule, activeModule, userType }) {
  return (
    <div className="adminMenu">
      <ul className="flex flex-row flex-wrap">
        {userType === "admin" && (
          <>
            <NavLink to="../orders">
              <li
                className={`adminMenu__item ${activeModule === "orders" ? "active" : ""}`}
                onClick={() => toggleModule("orders")}
              >
                Zamówienia
              </li>
            </NavLink>
            <NavLink to="../products">
              <li
                className={`adminMenu__item ${activeModule === "products" ? "active" : ""}`}
                onClick={() => toggleModule("products")}
              >
                Produkty
              </li>
            </NavLink>
            <NavLink to="../users">
              <li
                className={`adminMenu__item ${activeModule === "clients" ? "active" : ""}`}
                onClick={() => toggleModule("clients")}
              >
                Użytkownicy
              </li>
            </NavLink>
            <NavLink to="../discounts">
              <li
                className={`adminMenu__item ${activeModule === "discounts" ? "active" : ""}`}
                onClick={() => toggleModule("discounts")}
              >
                Kody rabatowe
              </li>
            </NavLink>
            <NavLink to="../ref-users">
              <li
                className={`adminMenu__item ${activeModule === "ref-users" ? "active" : ""}`}
                onClick={() => toggleModule("ref-users")}
              >
                Ambasadorzy
              </li>
            </NavLink>{" "}
          </>
        )}
        <NavLink to="../radzyn/orders">
          <li
            className={`adminMenu__item ${activeModule === "orders-rdz" ? "active" : ""}`}
            onClick={() => toggleModule("orders-rdz")}
          >
            Zamówienia Radzyń
          </li>
        </NavLink>
        <NavLink to="../radzyn/users">
          <li
            className={`adminMenu__item ${activeModule === "users-rdz" ? "active" : ""}`}
            onClick={() => toggleModule("users-rdz")}
          >
            Użytkownicy Radzyń
          </li>
        </NavLink>
        <NavLink to="../radzyn/products">
          <li
            className={`adminMenu__item ${activeModule === "products-rdz" ? "active" : ""}`}
            onClick={() => toggleModule("products-rdz")}
          >
            Produkty Radzyń
          </li>
        </NavLink>
      </ul>
    </div>
  );
}
