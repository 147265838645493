import { useState, useEffect } from "react";

import { usePostData } from "../../../controllers/fetchData";

export default function ProductUpdatePrice({ stock, id, type }) {
  const { response, idle, error, makePostRequest } = usePostData();

  const [isEditing, setIsEditing] = useState(false);
  const [quantity, setQuantity] = useState();

  useEffect(() => {
    setQuantity(stock);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStock = () => {
    const newQuantity = parseInt(quantity);
    const productId = id;
    const data = { productId, newQuantity, type };
    makePostRequest("admin/products/update-stock", data);
    console.log(data);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <div className="flex flex-col gap-4 justify-end items-end w-full">
          <label
            htmlFor="stock"
            className="text-right"
          >
            Ilość
          </label>
          <input
            className="form__input text-right w-full"
            type="number"
            name="stock"
            id="stock"
            defaultValue={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
          <span
            className="cursor-pointer text-right w-full"
            onClick={() => {
              updateStock();
            }}
          >
            Zapisz
          </span>
          <span
            className="cursor-pointer text-right w-full"
            onClick={() => setIsEditing(false)}
          >
            Anuluj
          </span>
        </div>
      ) : (
        <span
          className="cursor-pointer"
          onClick={() => setIsEditing(true)}
        >
          {quantity}
        </span>
      )}
      {/* {error && <p className="error">{error}</p>} */}
    </>
  );
}
