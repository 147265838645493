import { useState, useEffect } from "react";

import { usePostData } from "../../../controllers/fetchData";

export default function AdminUserAddNew({ location }) {
  const { response, idle, error, makePostRequest } = usePostData();

  const [email, setEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [type, setType] = useState("admin");

  useEffect(() => {
    if (location.pathname === "/radzyn/users") {
      setType("rdz3");
    } else if (location.pathname === "/users") {
      setType("client");
    }
  }, [location]);

  const addUser = () => {
    const data = {
      email: email,
      type: type,
    };
    makePostRequest(`admin/user-create`, data);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <div className="flex flex-col gap-4">
          <label htmlFor="email">Podaj email</label>
          <input
            type="text"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            className="w-72"
          />
          <button
            className="btn-sm"
            onClick={() => addUser()}
          >
            Dodaj
          </button>
          <button
            className="btn-sm"
            onClick={() => setIsEditing(false)}
          >
            Anuluj
          </button>
        </div>
      ) : (
        <>
          {error && <p>{error}</p>}
          {response && response.data.email ? (
            <p>
              Utworzono użytkownika: {response.data.email}. Jego hasło: <span className="font-bold">{response.data.password}</span>
            </p>
          ) : (
            <p>{response?.data}</p>
          )}
          <button
            className="btn-sm"
            onClick={() => setIsEditing(true)}
          >
            Dodaj nowego użytkownika
          </button>
        </>
      )}
    </>
  );
}
