import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ProductUpdatePrice from "./Products/ProductUpdatePrice";
import ProductUpdateStock from "./Products/ProductUpdateStock";

export default function AdminProductsTable({ products, type, isPennding, err }) {
  console.log(products);
  return (
    <div className="adminOrders">
      <h3>Produkty w sklepie</h3>
      <div className="hide-mobile adminProducts__row hover-none border-none">
        <div className="adminOrders__col prodName heading">Nazwa</div>
        {type !== "rdz" && <div className="adminOrders__col prodNetto heading">Brutto</div>}
        {type !== "rdz" && <div className="adminOrders__col prodNetto heading">Brutto P30</div>}
        {type !== "rdz" && <div className="adminOrders__col prodNetto heading">Brutto Gabinety</div>}
        <div className="adminOrders__col prodNetto heading">Brutto RDZ Autoryzowany</div>
        <div className="adminOrders__col prodNetto heading">Brutto RDZ Rekomendowany</div>
        <div className="adminOrders__col prodNetto heading">Brutto RDZ </div>
        {type !== "rdz" && <div className="adminOrders__col prodQty heading">Ilość</div>}
        <div className="adminOrders__col prodQty heading">Ilość RDZ</div>
      </div>
      {isPennding ? <div>Wczytywanie danych</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {products ? (
        <div className="adminProducts__table">
          {products.map((product) => {
            return (
              <div
                className="adminProducts__row"
                key={product.id}
              >
                <div className="adminOrders__col prodName"> {type !== "rdz" ? <NavLink to={`${product.id}`}>{product.name}</NavLink> : product.name}</div>
                {type !== "rdz" && (
                  <div className="adminOrders__col prodNetto">
                    <div className="hide-desktop price-description">Brutto</div>
                    <ProductUpdatePrice
                      value={product.brutto}
                      valueNetto={product.netto}
                      id={product.id}
                      name={product.name}
                      language={"pl"}
                      type={"main"}
                    />
                  </div>
                )}
                {type !== "rdz" && (
                  <div className="adminOrders__col prodNetto">
                    {" "}
                    <div className="hide-desktop price-description">Brutto P30</div>
                    <ProductUpdatePrice
                      value={product.brutto_a}
                      valueNetto={product.netto_a}
                      id={product.id}
                      name={product.name}
                      language={"pl"}
                      type={"a"}
                    />
                  </div>
                )}
                {type !== "rdz" && (
                  <div className="adminOrders__col prodNetto">
                    {" "}
                    <div className="hide-desktop price-description">Brutto Gabinety</div>
                    <ProductUpdatePrice
                      value={product.brutto_c}
                      valueNetto={product.netto_c}
                      id={product.id}
                      name={product.name}
                      language={"pl"}
                      type={"c"}
                    />
                  </div>
                )}
                <div className="adminOrders__col prodNetto">
                  {" "}
                  <div className="hide-desktop price-description">Brutto RDZ Autoryzowany</div>
                  <ProductUpdatePrice
                    value={product.brutto_b}
                    valueNetto={product.netto_b}
                    id={product.id}
                    name={product.name}
                    language={"pl"}
                    type={"b"}
                  />
                </div>
                <div className="adminOrders__col prodNetto">
                  {" "}
                  <div className="hide-desktop price-description">Brutto RDZ Rekomendowany</div>
                  <ProductUpdatePrice
                    value={product.brutto_d}
                    valueNetto={product.netto_d}
                    id={product.id}
                    name={product.name}
                    language={"pl"}
                    type={"d"}
                  />
                </div>
                <div className="adminOrders__col prodNetto">
                  {" "}
                  <div className="hide-desktop price-description">Brutto RDZ</div>
                  <ProductUpdatePrice
                    value={product.brutto_e}
                    valueNetto={product.netto_e}
                    id={product.id}
                    name={product.name}
                    language={"pl"}
                    type={"e"}
                  />
                </div>
                {type !== "rdz" && (
                  <div className="adminOrders__col prodQty">
                    <div className="hide-desktop price-description">Ilość</div>
                    <ProductUpdateStock
                      stock={product.stock}
                      id={product.id}
                      name={product.name}
                      type={"main"}
                    />
                  </div>
                )}
                <div className="adminOrders__col prodQty">
                  {" "}
                  <div className="hide-desktop price-description">Ilość Radzyń</div>
                  <ProductUpdateStock
                    stock={product.stock_rdz}
                    id={product.id}
                    name={product.name}
                    type={"rdz"}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {type !== "rdz" && (
        <NavLink to="/products/add">
          <button
            className="btn-sm marginTop"
            form="productForm"
          >
            Dodaj produkt
          </button>
        </NavLink>
      )}
    </div>
  );
}
